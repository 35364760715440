<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                ></v-progress-linear>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-menu
                                ref="modal"
                                v-model="modal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    solo
                                    v-model="start_date"
                                    label="Start Period"
                                    persistent-hint
                                    prepend-inner-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="start_date"
                                    type="month"
                                    no-title
                                    @input="modal = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-menu
                                ref="modal_to"
                                v-model="modal_to"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    solo
                                    v-model="end_date"
                                    label="End Period"
                                    persistent-hint
                                    prepend-inner-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="end_date"
                                    type="month"
                                    no-title
                                    @input="modal_to = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-autocomplete
                                prepend-inner-icon="mdi-clipboard-account"
                                clearable
                                solo
                                v-model="pic"
                                :items="pics"
                                item-value="pic_id"
                                item-text="pic_name"
                                label="PIC Name"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="3">
                            <v-autocomplete
                                prepend-inner-icon="mdi-badge-account"
                                clearable
                                solo
                                v-model="department"
                                :items="departments"
                                item-value="dept_id"
                                item-text="descr"
                                label="Department"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="2">
                            <v-autocomplete
                                prepend-inner-icon="mdi-layers"
                                clearable
                                solo
                                v-model="status"
                                :items="statuses"
                                item-value="value"
                                item-text="label"
                                label="Status"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="2">
                            <v-autocomplete
                                prepend-inner-icon="mdi-border-vertical"
                                clearable
                                solo
                                v-model="rawmat"
                                :items="rawmats"
                                item-value="value"
                                item-text="label"
                                label="Rawmat/General"
                                class="ma-0 pa-0"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                         <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="search()">Search</v-btn>
                            </v-col>
                        <!-- <v-col cols="12" xs="12" sm="5" md="2">
                            <v-btn
                                class="mr-2 rounded-l mt-1"
                                color="error"
                                elevation="2"
                                large
                                @click="search()"
                            >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                                Search
                            </v-btn>
                        </v-col>          -->
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="Result" style="border-top: 3px solid #1565C0">
                    <v-card-text>
                        <h6 class="indigo-text m-0">Query Result: </h6>
                        <div id="container1" style="height: 370px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="dialog" max-width="1200px">                
                    <v-card>
                        <v-card-title>Detail Purchase</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table :headers="headers" :items="purchasings" class="elevation-1" page-count="5" :search="searchDetail" :loading="loading3">
                                            <template v-slot:top>
                                                <v-toolbar flat color="white">
                                                    <div class="d-flex w-25">
                                                        <v-text-field v-model="searchDetail" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <!-- <template v-slot:[`item.wgt_ord`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_ord) }}
                                            </template>
                                            <template v-slot:[`item.wgt_rsv`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_rsv) }}
                                            </template>
                                            <template v-slot:[`item.wgt_ppp`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_ppp) }}
                                            </template>
                                            <template v-slot:[`item.wgt_deliv`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_deliv) }}
                                            </template>
                                            <template v-slot:[`item.wgt_shipped`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_shipped) }}
                                            </template>
                                            <template v-slot:[`item.wgt_stock`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_stock) }}
                                            </template>
                                            <template v-slot:[`item.wgt_plan`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_plan) }}
                                            </template>
                                            <template v-slot:[`item.wgt_outstanding`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_outstanding) }}
                                            </template> -->
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'SMS',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'Finance Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Purchasing Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            modal: false,
            start_date: '',
            modal_to: false,
            end_date: '',
            pics: [],
            pic: '',
            departments: [],
            department: '',
            statuses: [
                {
                    label: 'All',
                    value: 'All'
                },
                {
                    label: 'Close',
                    value: 'C'
                },
                {
                    label: 'Open',
                    value: 'O'
                },
            ],
            status: '',
            rawmats: [
                {
                    label: 'Rawmat',
                    value: 'Rawmat'
                },
                {
                    label: 'General',
                    value: 'General'
                }
            ],
            rawmat: '',
            headers: [],
            purchasings: [],
            searchDetail: '',
            loading3: false,
            dialog: false
        }
    },
    mounted(){
        this.$store.dispatch('setOverlay', false)
        this.getPIC()
        this.getDepartment()
    },
    methods:{
        close(){
            this.dialog = false
        },
        async getPIC(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/pic`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.pics = res.data
            });
        },
        async getDepartment(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/department`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.departments = res.data
            });
        },
        async search(){
            this.$store.dispatch('setOverlay', true)

            var start = this.start_date ? new Date(this.start_date).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end = this.end_date ? new Date(this.end_date).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var label = 'MillID: SMS';

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/finance/purchasing_analysis?txtStart=${start}&txtEnd=${end}&txtPICName=${this.pic}&txtDept=${this.department}&txtStatus=${this.status ? this.status : 'All'}&txtRaw=${this.rawmat ? this.rawmat : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)

                var dp1 = []; var dp2 = []; var dp3 = []; var dp4 = [];

                for (var i = 0; i < res.data.result.length; i++) {

                    dp1.push({  label: res.data.result[i].period,  y: parseFloat(res.data.result[i].step1) });
                    dp2.push({  label: res.data.result[i].period,  y: parseFloat(res.data.result[i].step2) });
                    dp3.push({  label: res.data.result[i].period,  y: parseFloat(res.data.result[i].step3) });
                    dp4.push({  label: res.data.result[i].period,  y: parseFloat(res.data.result[i].step4) });

                }
                var container = 'container1';
                var title = "Mill SMS Summary";

                this.getChart(dp1, dp2, dp3, dp4, container, title, label);

                $('html, body').animate({
                    scrollTop: $("#Result").offset().top
                }, 1200)
            });

        },
        getChart(dp1, dp2, dp3, dp4, container, title, label){

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "DAYS'",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.Step1,
                        showInLegend: true,
                        name: "PR -> APRVL",
                        indexLabel: "{y}",
                        // indexLabelPlacement: "inside",
                        // indexLabelOrientation: "vertical",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        // indexLabelFontColor: "#fff",
                        // color: "#325288"
                    },
                    {
                        type: "column",
                        click: this.Step2,
                        showInLegend: true,
                        name: "APRVL -> PO",
                        indexLabel: "{y}",
                        // indexLabelPlacement: "inside",
                        // indexLabelOrientation: "vertical",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        // indexLabelFontColor: "#fff",
                        // color: "#5b6d5b"
                    },
                    {
                        type: "column",
                        click: this.Step3,
                        showInLegend: true,
                        name: "PO -> RCV",
                        indexLabel: "{y}",
                        // indexLabelPlacement: "inside",
                        // indexLabelOrientation: "vertical",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        // indexLabelFontColor: "#fff",
                        // color: "#a35709"
                    },
                    {
                        type: "column",
                        click: this.Step4,
                        showInLegend: true,
                        name: "PR -> RCV",
                        indexLabel: "{y}",
                        // indexLabelPlacement: "inside",
                        // indexLabelOrientation: "vertical",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        // indexLabelFontColor: "#fff",
                        // color: "#a35709"
                    }
                ]
            });
            chart.options.data[0].dataPoints = dp1;
            chart.options.data[1].dataPoints = dp2;
            chart.options.data[2].dataPoints = dp3;
            chart.options.data[3].dataPoints = dp4;
            this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        Step1(e){
            console.log(e.dataPoint.label);
            this.purchasings = []
            this.headers = [
                { text: 'Period', value: 'period' },
                { text: 'PR ID', value: 'pr_id' },
                { text: 'Date PR', value: 'dt_pr' },
                { text: 'Date Apprv', value: 'dt_aprv' },
                { text: 'Interval', value: 'DayInterval' }
            ]
            this.detail('step1', e.dataPoint.label)
        },
        Step2(e){
            console.log(e.dataPoint.label);
            this.purchasings = []
            this.headers = [
                { text: 'Period', value: 'period' },
                { text: 'PR ID', value: 'pr_id' },
                { text: 'PO ID', value: 'po_id' },
                { text: 'PR Item', value: 'pr_item' },
                { text: 'Descr', value: 'descr' },
                { text: 'Date Apprv', value: 'dt_aprv' },
                { text: 'Date PO', value: 'dt_po' },
                { text: 'Interval', value: 'DayInterval' }
            ]
            this.detail('step2', e.dataPoint.label)
        },
        Step3(e){
            console.log(e.dataPoint.label);
            this.purchasings = []
            this.headers = [
                { text: 'Period', value: 'period' },
                { text: 'PR ID', value: 'pr_id' },
                { text: 'PR Item', value: 'pr_item' },
                { text: 'Descr', value: 'descr' },
                { text: 'PO ID', value: 'po_id' },
                { text: 'PO Item', value: 'po_item' },
                { text: 'RCV ID', value: 'rcv_id' },
                { text: 'Date PO', value: 'dt_po' },
                { text: 'Date RCV', value: 'dt_rcv' },
                { text: 'Interval', value: 'DayInterval' }
            ]
            this.detail('step3', e.dataPoint.label)
        },
        Step4(e){
            console.log(e.dataPoint.label);
            this.purchasings = []
            this.headers = [
                { text: 'Period', value: 'period' },
                { text: 'PR ID', value: 'pr_id' },
                { text: 'PR Item', value: 'pr_item' },
                { text: 'Descr', value: 'descr' },
                { text: 'PO ID', value: 'po_id' },
                { text: 'PO Item', value: 'po_item' },
                { text: 'RCV ID', value: 'rcv_id' },
                { text: 'Date PR', value: 'dt_pr' },
                { text: 'Date RCV', value: 'dt_rcv' },
                { text: 'Interval', value: 'DayInterval' }
            ]
            this.detail('step4', e.dataPoint.label)
        },
        async detail(step,period){
            this.dialog = true
            this.loading3 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/finance/purchasing_analysis/show?txtStep=${step}&txtParam=${period}&txtPICName=${this.pic}&txtDept=${this.department}&txtStatus=${this.status ? this.status : 'All'}&txtRaw=${this.rawmat ? this.rawmat : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading3 = false
                this.purchasings = res.data.result
            });
        }
    },
    watch: {
    }
}
</script>
